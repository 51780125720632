.materials-header {
    margin: auto;
    margin-top: 5px; 
    font-size: 3em;
    width: 100%;
    color: rgba(6, 88, 64, 1);
    font-weight: bold;
    margin-bottom: 10px;
}
.formCanvas {
    background-color: rgba(245, 245, 245, 0.95) !important; 
    border: none; 
    padding: 15px; 
    margin-bottom: 5px;
}
.formCanvasReview {
    background: linear-gradient(to right, rgba(169, 169, 169, 0.95) 0%,  rgba(245, 245, 245, 0.95) 75%);
    border: none; 
    padding: 15px; 
    margin-bottom: 5px;
}
.formCanvasSubmit {
    background-color: rgba(201, 201, 201, 0.95) !important; 
    border: none; 
    padding: 15px; 
    margin: auto;
    margin-top: 5px;
}
.addSampleButton {
    background-color: rgb(39, 39, 39) !important; 
    color: white !important;
    width: 230px;
    height: 40px;
    font-size: 1.0em !important;
}
.addSampleButtonDisabled {
    background-color: rgba(126, 132, 138, 0.65) !important; 
    color: rgb(204, 197, 197) !important;
    width: 230px;
    height: 40px;
    font-size: 1.0em !important;
}
.revButton {
    background-color: rgba(62, 155, 238, 1) !important; 
    color: white !important;
    width: 100%;
    font-size: 1.0em !important;
}
.prelimButton {
    background-color:white !important; 
    color:  rgba(38, 168, 137, 1) !important;     
    width: 100%;
    font-size: 1.0em !important;
}
.templateBtn {
    background-color: rgba(6, 88, 64, 1) !important; 
    border-radius: 10px !important;
    min-width: 390px;
    min-height: 50px;
    font-size: 1.7em !important;
    color: white !important;
    border: 0px;
    cursor: pointer;
}
.templateBtnAlt {
    background-color: black !important; 
    border-radius: 10px !important;
    width: 390px;
    height: 50px;
    font-size: 1.7em !important;
    color: white !important;
    border: 0px;
    cursor: pointer;
    float: left;
}
.newSubBtn {
    background-color: rgba(6, 88, 64, 1) !important; 
    border-radius: 10px !important;
    width: 100%;
    max-width: 300px;
    height: 50px;
    font-size: 1.7em !important;
    color: white;
    border: 0px;
    cursor: pointer;
    float: right;
}
.confirmButton {
    background-color: rgba(93, 168, 69, 1) !important; 
    color: white !important;
    width: 100%;
    font-size: 1.5em !important;
    border-radius: 30px !important;
}
.saveButton {
    background-color: rgb(190, 219, 180) !important; 
    color: black !important;
    width: 100%;
    font-size: 0.75rem !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    border: 3px solid rgba(93, 168, 69, 1) !important;
    min-height: 62px !important;
}
.matRelButton {
    background-color: green !important; 
    color: white !important;
    font-size: 2rem !important;
    border-radius: 15px !important;
    min-height: 62px !important;
    padding: 15px 30px 15px 30px !important;
}
.relButton {
    background-color: rgba(38, 168, 137, 1) !important; 
    color: white !important;
    width: 100%;
    font-size: 1.0em !important;
}
.signButton {
    background-color: rgb(67, 143, 64) !important; 
    color: white !important;
    width: 100%;
    height: 75px;
    font-size: 1.0em !important;
}
.disabledButton {
    background-color: rgba(126, 132, 138, 0.65) !important; 
    color: rgb(204, 197, 197) !important;
    width: 100%;
    height: 75px;
    font-size: 1.0em !important;
}
.formHeaderContainer {
    display: flex; 
    align-items: center; 
    text-align: left;
}
.formHeaderIcon {
    margin-right: 10px; 
    float: left;
}
#contents-menu {
    position: fixed;
    left: 0; 
    bottom: 8%;
}
#cl-logo {
    opacity: 100%;
    height: 65px;
    float: right;
    top: 10%;
    right: 0
}
#cl-logo-alt {
    display: none
}
#tent-logo {
    opacity: 100%;
    height: 50px;
    float: right;
    top: 10%;
    right: 0
}
#app-header {
    background-color: rgba(0, 10, 20, 0.333); 
    box-shadow: none; 
    color: white
}
#header-title {
    font-size: 30px
}
.admin-cannabis {
    background-color: #8cd98c;
}
.admin-cannabis-sampling {
    background-color: #339933;
}
.admin-environmental {
    background-color: #1D6AAF;
}
.admin-environmental-cell {
    color: white !important;
}
.admin-pws-environmental {
    background-color: #28beac;
}
.admin-kratom {
    background-color: #ffb380;
}
.admin-agriculture {
    background-color: #FFD966;
}
.admin-food {
    background-color:#BFA4DE;
}
.required-asterisk {
    color: red;
    font-size: 1.2em;
    font-weight: bold;
}

@media only screen and (max-width: 600px){
	#contents-menu {
        display: none;
    }
    #tent-logo {
        display: none;
    }
    #cl-logo {
        display: none;
    }
    #app-header {
        height: 75px;
        padding: 0px
    }
    #cl-logo-alt {
        opacity: 100%;
        height: 50px;
        float: right;
        top: 0;
        right: 0;
        display: block
    }
    #header-title {
        font-size: 15px
    }

}



@media only screen and (max-width: 500px) {

    .materials-header {
        font-size: 2em !important;
        margin-bottom: 5px;
    }
    .templateBtn {
        min-width: 80%;
    }
    .templateBtnAlt {
        min-width: 80%;
        float: inherit;
    }
    .newSubBtn {
        width: 90%;
        float: inherit;
    }
}