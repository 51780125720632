.section-to-print {
  position: relative;             
  padding: 2.5%;
  border: black 1px solid;       
  background-color: white;
  width: 95%;
  min-height: 300px;
  min-width: 400px;
  box-shadow: 0.25vw 0.25vw black;
  margin-bottom: 25px;
}
.page-break {
  display: none;
}
@media all {
  .page-break {
    display: none;
  }
}
@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
  .section-to-print {
    size: auto;    
    position: relative;             
    padding: 2.5%;
    border: none;       
    background-color: white;    
    box-shadow: none;
    margin-bottom: 0px;
  }
}
