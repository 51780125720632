.selected-table-container {
    box-shadow: none !important;
    background-color: RGBA(0, 0, 0, 0) !important;
    padding: 0px !important;
}

.selected-table{
    border-collapse: separate !important;
    border-spacing: 0 10px !important;
    
    background-color: RGBA(0, 0, 0, 0) !important;
}

.selected-table th, .selected-table td {
    border-top: 0px solid black !important;
    border-bottom: 0px solid black !important;
}

.testing-header {
    background-color: black !important;
}

.testing-row {
    background-color: white;
}
.formHeaderContainer {
    display: flex; 
    align-items: center; 
    text-align: left;
}