.header-tab {
    margin-right: 4px;
    padding: 5px;
}

.header {
    overflow: scroll;
    padding-bottom: 10px;
}

.on {
    background-color: #EBEBEB;
}
.off {
    background-color: #C1C1C1; 
}

.render-body {
    padding-top: 10px;
}