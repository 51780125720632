@import url('https://fonts.cdnfonts.com/css/freestyle-script');

.documentControl {
  margin: auto; 
  padding: 1%; 
  background-color: grey;
  width: 95%;
}
.COCScrollContainer {
}
.COCBody {            
  position: relative;             
  padding: 2.5%;
  border: black 0.5% solid;       
  background-color: white;
  width: 95%;
  box-shadow: 0.25vw 0.25vw black;
}
.methodHeader
{
  vertical-align: center;
  text-align: center;
  border: black 0.075vw solid !important;  
  padding: 0px !important;  
}
.methodHeaderText {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  font-size: 0.8vw !important;
  transform: rotate(180deg);
  white-space: nowrap;
  padding: 0px !important;  
  margin: 0px !important;
  font-size: 0.8vw !important;
  
}
.contactInputText {
    text-decoration: underline;   
}
.contactInputleader {    
  font-size: 1vw !important;   
  
}
.contactCell {
  padding: 0px !important;  
  margin: 0px !important;
  text-align: left;
  border: 0px !important;  
}
.cocCell {
    border: 0.075vw solid black !important;    
    padding: 0px !important;
    margin: 0px !important;
    font-size: 0.8vw !important;
    text-align: center !important;
}
.cocCellComment {
  border: 0.075vw solid black !important;    
  padding: 0px 10px 0px 10px !important;
  margin: 0px !important;
  font-size: 0.8vw !important;
  text-align: center !important;
  p {
    margin: 0px !important;
  }
}
.cocCellBottle {
    border: 0.075vw solid black !important;    
    border-left: 0.2vw solid black !important;     
    padding: 0px !important;
    margin: 0px !important;
    font-size: 0.8vw !important;
    text-align: center !important;
}
.cocCellBottleSampleFirst {
    max-width: 1.75vw;
    word-wrap: break-word;
    border: 0.075vw solid black !important;    
    border-left: 0.2vw solid black !important;     
    padding: 0px !important;
    margin: 0px !important;
    font-size: 0.5vw !important;
    text-align: center !important;
}
.cocCellBottleSample {
    max-width: 1.75vw;
    word-wrap: break-word;
    border: 0.075vw solid black !important;    
    padding: 0px !important;
    margin: 0px !important;
    font-size: 0.5vw !important;
    text-align: center !important;
}
.methodHeaderCell{
  border: 0.075vw solid black !important;    
  padding-right: 0em !important;        
  padding-left: 0.05em !important; 
  padding-top: 0.5em !important;       
}
.firstBottleHeaderCell{
  border: 0.075vw solid black !important;  
  border-left: 0.2vw solid black !important;     
  padding-right: 0em !important;        
  padding-left: 0.05em !important; 
  padding-top: 0.5em !important;       
}
.signatureCell {
  border: 0.1vw solid black !important;    
  padding: 0px !important;
  margin: 0px !important;
  font-size: 1.5vw !important;
  font-weight: 550 !important;
  background-color: rgb(190, 233, 190);
  text-align: center !important;
  font-family: Brush Script MT,cursive;
  height: 1.6vw;
}
.labUseCell {
  border: 0.1vw solid black !important;    
  padding: 0px !important;
  margin: 0px !important;
  font-size: 1.5vw !important;
  font-weight: 550 !important;
  background-color: rgb(178, 189, 178);
  text-align: center !important;
  font-family: Brush Script MT,cursive;
  height: 1.6vw;
}
.signatureCellHeader {
  border: 0.1vw solid black !important;    
  padding: 0px !important;
  font-weight: bold !important;
  margin: 0px !important;
  font-size: 1vw !important;
  text-align: center !important;
}
.signatureContainer {    
  width: "33%"
}
.cocContainerCell {       
    padding: 0px !important;
    margin: 0px !important;
    border: black 0px solid !important;
}
.cocRow {
    border: black 0.075vw solid !important;
}
.cocCheck {
  font-size: 1vw !important;
}
.clientArea {
  border: 0.075vw solid black !important;    
}

.mainHeaderText {
  font-size: 1.3vw;
  font-weight: bold;
  word-break: break-word;
}

.mainHeaderTextMinor {
  font-size: 1.0vw;

}
.mainFooterText { 
  font-style: italic;
  font-size: 0.75vw
} 
.mainFooterTextBody {
  font-style: italic;
  font-size: 0.75vw;
  padding: 25px;
  padding-bottom: 1em;
}
@media (max-width: 640px) {
  .COCScrollContainer {
    overflow: scroll;
  }
  .mainFooterTextBody {
    font-size: 8px;
  }
  .mainFooterText { 
    font-size: 8px
  } 
.methodHeaderText {
  font-size: 8px !important;
  
}
.contactInput {
    font-size: 8px !important;
}
.contactInputleader {   
  font-size: 8px !important;
  
}

.contactCell {
  
}
.cocCell {
    font-size: 8px !important;    
    
}
.methodHeaderCell{
  
}
.signatureCell {
  font-size: 15px !important;
}
.signatureCellHeader {
  font-size: 8px !important;
}
.signatureContainer {    
  width: "33%"
}
.cocContainerCell {       
}
.cocRow {
}
.cocCheck {
  font-size: 8px !important;
}
.COCScrollContainer {
  height: 400px;
}
.COCBody {            
  width: 650px;
}
.mainHeaderText {
  font-size: 10px;
  font-weight: bold;
}

.mainHeaderTextMinor {
  font-size: 8px;

}

  }


  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
/* 
* {
	box-sizing: border-box;
}

body {
	background-image: linear-gradient(to bottom, #cfd9df 0%, #e2ebf0 100%);
	font-family: 'Source Sans Pro', sans-serif;
	margin: 0;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
} */

.blobs-container {
	display: flex;
}

.blob {
	background: black;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse-red 2s infinite;
}

@keyframes pulse-black {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}
