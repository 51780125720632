.backdropImage {
   -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center center;
    transform-origin: center top;
    box-sizing: inherit;
    z-index: -100;
    width: 100%;
}