.material-summary-body {
    width: 100%;
    min-height: 200px;
    span {
        font-size: 1.2em !important;
    }
}

.column-header {
    font-size: 1.3em !important;
}

.summary-text {
    font-size: 1.1em !important;
}

.mat-sum-bookend {    
    border-top-left-radius: 15px;
    background: linear-gradient(to right, rgba(0,0,0,0.2), rgba(255, 255, 255, 1));
    
}
.mat-sum-bookend-right {
    border-top-right-radius: 15px;
    background: linear-gradient(to left, rgba(0,0,0,0.2), rgba(255, 255, 255, 1));
}
.mat-sum-body {
    background-color: white;
}

@media only screen and (max-width: 1500px) {
    
    .material-summary-body {
        span {
            font-size: 1.1em !important;
        }
    }
    .logo-header {
        display: none;
    }
  }